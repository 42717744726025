import invariant from 'tiny-invariant'
import { ChainId } from '../constants'
import { validateAndParseAddress } from '../utils'
import { Currency } from './currency'

/**
 * Represents an ERC20 token with a unique address and some metadata.
 */
export class Token extends Currency {
  public readonly chainId: ChainId
  public readonly address: string

  public constructor(chainId: ChainId, address: string, decimals: number, symbol?: string, name?: string) {
    super(decimals, symbol, name)
    this.chainId = chainId
    this.address = validateAndParseAddress(address)
  }

  /**
   * Returns true if the two tokens are equivalent, i.e. have the same chainId and address.
   * @param other other token to compare
   */
  public equals(other: Token): boolean {
    // short circuit on reference equality
    if (this === other) {
      return true
    }
    return this.chainId === other.chainId && this.address === other.address
  }

  /**
   * Returns true if the address of this token sorts before the address of the other token
   * @param other other token to compare
   * @throws if the tokens have the same address
   * @throws if the tokens are on different chains
   */
  public sortsBefore(other: Token): boolean {
    invariant(this.chainId === other.chainId, 'CHAIN_IDS')
    invariant(this.address !== other.address, 'ADDRESSES')
    return this.address.toLowerCase() < other.address.toLowerCase()
  }
}

/**
 * Compares two currencies for equality
 */
export function currencyEquals(currencyA: Currency, currencyB: Currency): boolean {
  if (currencyA instanceof Token && currencyB instanceof Token) {
    return currencyA.equals(currencyB)
  } else if (currencyA instanceof Token) {
    return false
  } else if (currencyB instanceof Token) {
    return false
  } else {
    return currencyA === currencyB
  }
}

export const WETH = {
  [ChainId.BASE]: new Token(ChainId.BASE, '0x4200000000000000000000000000000000000006', 18, 'WETH', 'Wrapped Ether'),
  [ChainId.BASE_GOERLI]: new Token(ChainId.BASE_GOERLI, '0x4200000000000000000000000000000000000006', 18, 'WETH', 'Wrapped Ether'),
  [ChainId.LINEA]: new Token(ChainId.LINEA, '0xe5D7C2a44FfDDf6b295A15c148167daaAf5Cf34f', 18, 'WETH', 'Wrapped Ether'),
  [ChainId.LINEA_GOERLI]: new Token(ChainId.LINEA_GOERLI, '0x2C1b868d6596a18e32E61B901E4060C872647b6C', 18, 'WETH', 'Wrapped Ether'),
  [ChainId.OP_BNB_TESTNET]: new Token(ChainId.OP_BNB_TESTNET, '0x4200000000000000000000000000000000000006', 18, 'WBNB', 'Wrapped BNB'),
  [ChainId.OP_BNB]: new Token(ChainId.OP_BNB, '0x4200000000000000000000000000000000000006', 18, 'WBNB', 'Wrapped BNB'),
  [ChainId.ZKSYNC]: new Token(ChainId.ZKSYNC, '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91', 18, 'WETH', 'Wrapped Ether'),
  [ChainId.ARBITRUM]: new Token(ChainId.ARBITRUM, '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1', 18, 'WETH', 'Wrapped Ether'),
  [ChainId.ZETA_TESTNET]: new Token(ChainId.ZETA_TESTNET, '0x5f0b1a82749cb4e2278ec87f8bf6b618dc71a8bf', 18, 'WETH', 'Wrapped Ether'),
  [ChainId.SCROLL_TESTNET]: new Token(ChainId.SCROLL_TESTNET, '0x5300000000000000000000000000000000000004', 18, 'WETH', 'Wrapped Ether'),
  [ChainId.BRISE]: new Token(ChainId.BRISE, '0x0eb9036cbE0f052386f36170c6b07eF0a0E3f710', 18, 'WBRISE', 'Wrapped BRISE'),
  [ChainId.OMNI_TESTNET]: new Token(ChainId.OMNI_TESTNET, '0x1212400000000000000000000000000000000003', 18, 'WOMNI', 'Wrapped OMNI'),
  [ChainId.TAIKO_TESTNET]: new Token(ChainId.TAIKO_TESTNET, '0xf86E3b4cde65c0cd59580Ee3F8dd34343E23ea5B', 18, 'WETH', 'Wrapped Ether'),
  [ChainId.SCROLL]: new Token(ChainId.SCROLL, '0x5300000000000000000000000000000000000004', 18, 'WETH', 'Wrapped Ether'),
  [ChainId.MANTA]: new Token(ChainId.MANTA, '0x0Dc808adcE2099A9F62AA87D9670745AbA741746', 18, 'WETH', 'Wrapped Ether'),
  // [ChainId.MANTLE]: new Token(ChainId.MANTLE, '0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8', 18, 'WMANTLE', 'Wrapped Mantle'),

}
