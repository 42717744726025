import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | bigint | string

export enum ChainId {
  BASE_GOERLI = 84531,
  BASE = 8453,
  LINEA = 59144,
  LINEA_GOERLI = 59140,
  OP_BNB_TESTNET = 5611,
  OP_BNB=204,
  ZKSYNC= 324,
  ARBITRUM=42161,
  ZETA_TESTNET=7001,
  //MANTLE= 5000,
  SCROLL_TESTNET=534351,
  BRISE= 32520,
  OMNI_TESTNET=165,
  TAIKO_TESTNET=167007,
  SCROLL=534352,
  MANTA=169
} 

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP
}

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const FACTORY_ADDRESS: { [chainId in ChainId]: string } = {
  [ChainId.BASE]: '0xD07739a9E9C46D3DedeD97c0edC49cea8BAB1Bb9',
  [ChainId.BASE_GOERLI]: '0xD07739a9E9C46D3DedeD97c0edC49cea8BAB1Bb9',
  [ChainId.LINEA]: '0xD07739a9E9C46D3DedeD97c0edC49cea8BAB1Bb9',
  [ChainId.LINEA_GOERLI]: '0xD07739a9E9C46D3DedeD97c0edC49cea8BAB1Bb9',
  [ChainId.OP_BNB_TESTNET]: '0xD07739a9E9C46D3DedeD97c0edC49cea8BAB1Bb9',
  [ChainId.OP_BNB]: '0xD07739a9E9C46D3DedeD97c0edC49cea8BAB1Bb9',
  [ChainId.ZKSYNC]: '0x8435bd22e705DCeFCf6EF8b921E6dB534a4E9902',
  [ChainId.ARBITRUM]: '0xD07739a9E9C46D3DedeD97c0edC49cea8BAB1Bb9',
  [ChainId.ZETA_TESTNET]: '0xD07739a9E9C46D3DedeD97c0edC49cea8BAB1Bb9',
  [ChainId.SCROLL_TESTNET]: '0xD07739a9E9C46D3DedeD97c0edC49cea8BAB1Bb9',
  [ChainId.BRISE]: '0x2A9199DEF6777ca72AaA7b6588b4C39664920BA2',
  [ChainId.OMNI_TESTNET]: '0xD07739a9E9C46D3DedeD97c0edC49cea8BAB1Bb9',
  [ChainId.TAIKO_TESTNET]: '0xD07739a9E9C46D3DedeD97c0edC49cea8BAB1Bb9',
  [ChainId.SCROLL]: '0xD07739a9E9C46D3DedeD97c0edC49cea8BAB1Bb9',
  [ChainId.MANTA]: '0x0932e44A456092bF5724570d81e192999C493A29',

  //[ChainId.MANTLE]: '0x2A9199DEF6777ca72AaA7b6588b4C39664920BA2'
}

export const INIT_CODE_HASH: { [chainId in ChainId]: string } = {
  [ChainId.BASE]: '0xa6989c59e22818841eefd99458f30c45d0d5e6d1489d1a2369c5effb38f9773d',
  [ChainId.BASE_GOERLI]: '0xa6989c59e22818841eefd99458f30c45d0d5e6d1489d1a2369c5effb38f9773d',
  [ChainId.LINEA]: '0xa6989c59e22818841eefd99458f30c45d0d5e6d1489d1a2369c5effb38f9773d',
  [ChainId.LINEA_GOERLI]: '0xa6989c59e22818841eefd99458f30c45d0d5e6d1489d1a2369c5effb38f9773d',
  [ChainId.OP_BNB_TESTNET]: '0xa6989c59e22818841eefd99458f30c45d0d5e6d1489d1a2369c5effb38f9773d',
  [ChainId.OP_BNB]: '0xa6989c59e22818841eefd99458f30c45d0d5e6d1489d1a2369c5effb38f9773d',
  [ChainId.ZKSYNC]: '0x0100065db377e58f8a33c21a75937a9740f1225b3f760df7047e2454f4655868',
  [ChainId.ARBITRUM]: '0x2a4ab8afe5e76a9284d13da8e7953dc47a07337190ad966cc92bb2c89db4b367',
  [ChainId.ZETA_TESTNET]: '0xa6989c59e22818841eefd99458f30c45d0d5e6d1489d1a2369c5effb38f9773d',
  [ChainId.SCROLL_TESTNET]: '0x2a4ab8afe5e76a9284d13da8e7953dc47a07337190ad966cc92bb2c89db4b367',
  [ChainId.BRISE]: '0x873f467a52cfa359fb4a0e67f4bfec3627809b91992ed98ae899d250c0b05ed9',
  [ChainId.OMNI_TESTNET]: '0xa6989c59e22818841eefd99458f30c45d0d5e6d1489d1a2369c5effb38f9773d',
  [ChainId.TAIKO_TESTNET]: '0x2a4ab8afe5e76a9284d13da8e7953dc47a07337190ad966cc92bb2c89db4b367',
  [ChainId.SCROLL]: '0x2a4ab8afe5e76a9284d13da8e7953dc47a07337190ad966cc92bb2c89db4b367',
  [ChainId.MANTA]: '0x2a4ab8afe5e76a9284d13da8e7953dc47a07337190ad966cc92bb2c89db4b367',
  //[ChainId.MANTLE]: '0xa6989c59e22818841eefd99458f30c45d0d5e6d1489d1a2369c5effb38f9773d'
}
export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const _997 = JSBI.BigInt(997)
export const _1000 = JSBI.BigInt(1000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256'
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
}
